import React from "react";

const AcademicDetails = () => {
  const academicData = [
    {
      degree: "Bachelor of Engineering, Computer ",
      institution: "Tribhuvan University, IOE, Pulchowk Campus",
      year: "2021 - 2025(Expected)",
      icon: "🎓", // Emoji or icon for visual appeal
    },
    {
      degree: "Higher Secondary School",
      institution: "Uniglobe College",
      year: "2019 - 2021",
      icon: "📚", // Emoji or icon for visual appeal
    },
    {
      degree: "Secondary Education Examination(SEE)",
      institution: "Paragon Academy",
      year: "2018",
      icon: "🏫", // Emoji or icon for visual appeal
    },
  ];

  return (
    <section className="py-16 bg-gradient-to-br from-blue-50 to-indigo-100 dark:from-slate-900 dark:to-slate-900 dark:bg-slate-900 dark:border-b-2 dark:border-slate-800">
      <div className="container mx-auto px-4 dark:bg-slate-900">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12 dark:text-white">
          Academic Journey
          <span className="block mt-2 text-2xl text-indigo-600 dark:text-indigo-500 font-medium">
            Where I Learned and Grew
          </span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {academicData.map((academic, index) => (
            <div
              key={index}
              className="bg-white dark:bg-slate-800 rounded-xl shadow-xl overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
            >
              <div className="p-8 text-center">
                <div className="text-6xl mb-6">{academic.icon}</div>
                <h3 className="text-2xl font-bold text-gray-800 mb-4 dark:text-white">
                  {academic.degree}
                </h3>
                <p className="text-lg text-indigo-600 font-semibold mb-2 dark:text-indigo-500">
                  {academic.institution}
                </p>
                <p className="text-sm text-gray-500 mb-4 dark:text-gray-400">
                  {academic.year}
                </p>
                <p className="text-gray-700 leading-relaxed">
                  {/* {academic.description} */}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AcademicDetails;
