import "./App.css";

import { Routes, Route } from "react-router-dom";
import { NavBar } from "./components/Navbar";
import HomePage from "./pages/HomePage";
import ProjectSection from "./pages/ProjectPage";

function App() {
  return (
    <div className="min-h-screen bg-white text-gray-900 transition-colors duration-200 dark:bg-slate-900 dark:bg-gradient-to-[315deg] dark:from-slate-900 dark:to-[#12100e]">
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectSection />} />
      </Routes>
    </div>
  );
}

export default App;
