import React from "react";
import { Hero } from "../components/Hero";
import Footer from "../components/Footer";
import About from "../components/About";
import AcademicDetails from "../components/AcademicDetails";
import AchievementsSection from "../components/Achievements";
import SkillsSection from "../components/SkillsSection";

export default function HomePage() {
  return (
    <>
      <Hero />
      <About />
      <AcademicDetails />
      <AchievementsSection />
      <SkillsSection />
      {/* <Skills /> */}
      <Footer />
    </>
  );
}
