import { Mail } from "lucide-react";
import { FaGithubSquare } from "react-icons/fa";

export const Hero = () => (
  <div className="h-1/2 mt-16 flex items-center justify-center bg-gradient-to-br from-blue-100 to-indigo-100 py-24 dark:bg-slate-900 dark:bg-gradient-to-[315deg] dark:from-slate-900 dark:to-[#12100e] relative overflow-hidden">
    {/* Circular cloud background */}
    <div className="absolute">
      <div className="w-[500px] h-[400px] rounded-full bg-indigo-300/50 dark:bg-indigo-500/10 blur-3xl"></div>
    </div>

    {/* Content with relative positioning to appear above the cloud */}
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
      <div className="space-y-8">
        <h1 className="text-5xl font-bold text-gray-900 dark:text-white">
          Hi, I'm{" "}
          <span className="text-indigo-600 dark:text-indigo-500 text-semibold">
            Prasun Sitaula
          </span>
        </h1>

        <p className="text-xl text-gray-600 dark:text-gray-300 max-w-2xl mx-auto">
          Full Stack Developer passionate about building exceptional digital
          experiences. Specialized in React, NextJS, Node.js, and modern web
          technologies.
        </p>
        <div className="flex justify-center space-x-6">
          <a
            href="https://github.com/PRASUN-SITAULA"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-600 dark:hover:text-white"
          >
            <FaGithubSquare size={24} />
          </a>
          <a
            href="mailto:psitaula209@gmail.com"
            className="text-gray-600 dark:hover:text-white"
          >
            <Mail size={24} />
          </a>
        </div>
      </div>
    </div>
  </div>
);
