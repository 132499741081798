import React from "react";
import ProfileImage from "../assets/images/my_photo.jpg";
import { FaGithubSquare } from "react-icons/fa";

const About = () => {
  return (
    <section
      id="about"
      className="py-10 bg-white dark:bg-slate-900 dark:border-b-2 dark:border-slate-800"
    >
      <div className="container mx-auto px-6 ">
        <h2 className="text-4xl font-bold text-center mb-8 dark:text-white">
          About Me
          <span className="block mt-2 text-2xl text-indigo-600 dark:text-indigo-500 font-medium">
            Who I Am
          </span>
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-center md:items-start gap-x-16">
          <div className="mb-8 md:mb-0">
            <img
              src={ProfileImage}
              alt="About Me"
              className="w-60 h-60 rounded-full shadow-lg object-cover"
            />
          </div>
          <div className="md:w-1/2 md:pl-10">
            <p className="text-gray-600 dark:text-gray-400 mb-4 text-left">
              I'm a passionate web developer with expertise in NextJs, React,
              Node.js, and modern web technologies. Specializing in building
              performant, scalable applications with a focus on SEO optimization
              and accessibility standards. With a keen eye for design and a love
              for clean code, I create engaging and user-friendly web
              applications. I am experienced in API integration, state
              management solutions, and implementing responsive design
              principles.
            </p>
            <div className="flex space-x-4">
              <a href="https://github.com/PRASUN-SITAULA">
                <FaGithubSquare className="w-6 h-6 dark:text-gray-500 dark:hover:text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
