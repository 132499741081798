import React from "react";

const AchievementsSection = () => {
  const achievementsData = [
    {
      title: "Hack-A-Week 2025 Organizer",
      description:
        "Organized a Hack-A-Week event in my college, where I worked on various projects and challenges.",
      icon: "🏆", // Emoji or icon for visual appeal
      color: "bg-yellow-100", // Background color for the icon
    },
    {
      title: "Open Source Contributor",
      description:
        "Contributed to multiple open-source projects, including React and Tailwind CSS libraries.",
      icon: "🌐", // Emoji or icon for visual appeal
      color: "bg-blue-100", // Background color for the icon
    },
    {
      title: "Certified Developer",
      description:
        "Achieved certifications in Full-Stack Development, Cybersecurity, and DevOps.",
      icon: "📜", // Emoji or icon for visual appeal
      color: "bg-green-100", // Background color for the icon
    },
    {
      title: "Locus Software Fellowship Mentor",
      description:
        "Mentored and guided fellow students in web development and deployment.",
      icon: "💻", // Emoji or icon for visual appeal
      color: "bg-purple-200", // Background color for the icon
    },
    {
      title: "Advent of Cyber 2024",
      description:
        "Completed the Advent of Cyber 2024 challenge, which involved solving various cybersecurity-related problems.",
      icon: "👩‍💻", // Emoji or icon for visual appeal
      color: "bg-pink-200", // Background color for the icon
    },
    // {
    //   title: "Published Researcher",
    //   description:
    //     "Published a research paper on AI and its applications in healthcare in a reputed journal.",
    //   icon: "📄", // Emoji or icon for visual appeal
    //   color: "bg-pink-100", // Background color for the icon
    // },
    // {
    //   title: "Community Speaker",
    //   description:
    //     "Spoke at tech conferences and meetups about modern web development and AI trends.",
    //   icon: "🎤", // Emoji or icon for visual appeal
    //   color: "bg-indigo-100", // Background color for the icon
    // },
  ];

  return (
    // <section className="py-16 bg-gradient-to-br from-gray-900 to-gray-800">
    <section className="py-16 bg-blue-50 dark:bg-slate-900 dark:border-b-2 dark:border-slate-800">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-black mb-12 dark:text-white">
          Achievements
          <span className="block mt-2 text-2xl text-indigo-600 dark:text-indigo-500 font-medium">
            What I’m Proud Of
          </span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {achievementsData.map((achievement, index) => (
            <div
              key={index}
              className="bg-white dark:bg-slate-800 rounded-xl shadow-2xl overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-3xl"
            >
              <div className="p-8 text-center">
                <div
                  className={`w-16 h-16 ${achievement.color} rounded-full flex items-center justify-center mx-auto mb-6 text-3xl`}
                >
                  {achievement.icon}
                </div>
                <h3 className="text-2xl font-bold text-black mb-4 dark:text-white">
                  {achievement.title}
                </h3>
                <p className="text-gray-500 leading-relaxed dark:text-gray-400">
                  {achievement.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AchievementsSection;
