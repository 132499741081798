import React from "react";
import { FaGithubSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-6 flex flex-col gap-2 justify-center items-center">
        <div className="mb-4 md:mb-0">
          <p>&copy; 2024 Prasun Sitaula. All rights reserved.</p>
        </div>
        <div className="flex space-x-4">
          <a
            href="https://github.com/PRASUN-SITAULA"
            className="hover:text-gray-300"
          >
            <FaGithubSquare className="w-6 h-6 dark:text-gray-500 dark:hover:text-white" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
