import { CodeBracketIcon, EyeIcon } from "@heroicons/react/24/outline";
import healthTrack from "../assets/images/health_track.png";
import vehicleDetection from "../assets/images/vehicle_detection.png";
import carbonWise from "../assets/images/carbon_wise.png";
import jobScrapper from "../assets/images/job_scrapper.png";
import chitChat from "../assets/images/chit_chat.png";

const ProjectsSection = () => {
  const projectsData = [
    {
      title: "Chit Chat",
      description:
        "ChitChat is a modern, user-friendly instant messaging application that enables seamless communication between users. ChitChat provides a reliable and engaging platform for real-time communication with friends and family.",
      image: `${chitChat}`,
      tech: ["NextJS", "TailwindCSS", "Supabase", "Clerk", "Pusher"],
      link: "",
      github: "https://github.com/PRASUN-SITAULA/chitChat",
    },
    {
      title: "Health Tracker",
      description:
        "Health Track is a web application designed to help users track their health metrics like height, weight and blood sugar and monitor their progress towards their health goals. The application provides users with a user-friendly interface to input their health metrics and view their progress.",
      tech: ["Next.js", "Tailwind", "Shadcn UI", "Supabase", "lucia-auth"],
      image: `${healthTrack}`,
      link: "https://health-track-virid.vercel.app",
      github: "https://github.com/PRASUN-SITAULA/healthTrack",
    },
    {
      title: "Vehicle Detection and Tracking",
      description:
        "Our Minor Project using YOLO for vehicle detection, counting and tracking from videos. Our system utilizes a YOLO-based object detection model to address traffic management problems by implementing vehicle tracking, classification, counting, and speed measurement.",
      image: `${vehicleDetection}`,
      tech: ["Python", "YOLO", "React", "FastAPI"],
      link: "",
      github:
        "https://github.com/NayanPandey371/Vehicle-Detection-and-Tracking.git",
    },
    {
      title: "Carbon Wise",
      description:
        "A LLM powered web app that calculates the carbon footprint of a family and gives advisory to reduce the carbon footprint. CarbonWise harnesses the formidable capabilities of OpenAI's Language Model (LLM) via the OpenAI API to empower individuals to make informed decisions for a greener future.",
      image: `${carbonWise}`,
      tech: ["React", "Express", "LLM", "Langchain"],
      link: "",
      github: "https://github.com/PRASUN-SITAULA/carbonWise.git",
    },
    {
      title: "Job Scrapper",
      description:
        "JobScrapper is a web application designed to simplify the job search process for individuals interested in roles within the technology industry. Leveraging the power of web scraping , careerQuest provides users with a streamlined interface to explore job listings tailored to three key categories: Software Engineer, UI Designer, and Hardware Engineer.",
      image: `${jobScrapper}`,
      tech: ["React", "Express", "Puppeteer"],
      link: "",
      github: "https://github.com/PRASUN-SITAULA/jobScrapper",
    },
    // {
    //   title: "Chit Chat",
    //   description: "A NextJS messaging app.",
    //   image: "",
    //   link: "https://github.com/PRASUN-SITAULA/chitChat",
    // },
    // {
    //   title: "Carbon Wise",
    //   description:
    //     "A LLM powered web app that calculates the carbon footprint of a family and gives advisory to reduce the carbon footprint.",
    //   image: "",
    //   link: "https://github.com/PRASUN-SITAULA/carbonWise.git",
    // },
    // {
    //   title: "gym-exercises.",
    //   description:
    //     "A simple react app that used API to fetch exercises and display them. It also has a search bar to search for exercises.",
    //   image: "",
    //   link: "https://github.com/PRASUN-SITAULA/gym-exercises.git",
    // },
    // {
    //   title: "Blog Application",
    //   description: "A simple blog application in react.",
    //   image: "",
    //   link: "https://github.com/PRASUN-SITAULA/Blog-Application.git",
    // },
    // {
    //   title: "Chat Application in Django.",
    //   description: "A chatting site in django.",
    //   image: "",
    //   link: "https://github.com/PRASUN-SITAULA/Chat-Application.git",
    // },
    // {
    //   title: "Calorie Counter.",
    //   description: "A simple Django site that tracks calories consumed.",
    //   image: "",
    //   link: "https://github.com/PRASUN-SITAULA/Calorie-Counter.git",
    // },
    // {
    //   title: "Alumni Tracker",
    //   description:
    //     "A web application based on Django for tracking college alumni. It was a software engineering project continued on by our team. We added features like search functionality and dockerized the application for deployment.",
    //   image: "",
    //   link: "https://github.com/PRASUN-SITAULA/Alumni-Tracker.git",
    // },
    // {
    //   title: "E-Commerce Platform",
    //   description:
    //     "Full-stack e-commerce solution with Next.js, Stripe integration, and inventory management",
    //   tech: ["Next.js", "TypeScript", "PostgreSQL", "Tailwind", "Docker"],
    //   image: "/project1.jpg",
    //   link: "#",
    //   github: "#",
    // },
    // {
    //   title: "DevOps Dashboard",
    //   description:
    //     "Centralized monitoring system for Kubernetes clusters with real-time analytics",
    //   tech: ["React", "Go", "Kubernetes", "Prometheus", "Grafana"],
    //   image: "/project2.jpg",
    //   link: "#",
    //   github: "#",
    // },
    // {
    //   title: "Security Audit Tool",
    //   description:
    //     "Automated vulnerability scanner for web applications with detailed reporting",
    //   tech: ["Python", "Django", "Celery", "Redis", "AWS"],
    //   image: "/project3.jpg",
    //   link: "",
    //   github: "#",
    // },
  ];

  return (
    <section className="py-16 bg-gradient-to-br from-gray-50 to-blue-50 dark:from-slate-900 dark:to-slate-900 dark:bg-slate-900">
      <div className="container mx-auto px-4 mt-8">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12 dark:text-white">
          Featured Projects
          <span className="block mt-2 text-2xl text-indigo-600 font-medium dark:text-indigo-500">
            My Development Work
          </span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projectsData.map((project, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-[1.02] hover:shadow-2xl dark:bg-slate-800"
            >
              {/* Project Image */}
              <div className="relative group">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-105"
                />
                {/* Image Overlay Links */}
                <div className="absolute inset-0 bg-black/50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <div className="flex gap-4">
                    {project.link && (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={project.link}
                        className="p-3 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
                        aria-label="Live Preview"
                      >
                        <EyeIcon className="w-6 h-6 text-white" />
                      </a>
                    )}

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={project.github}
                      className="p-3 rounded-full bg-white/10 hover:bg-white/20 transition-colors"
                      aria-label="View Code"
                    >
                      <CodeBracketIcon className="w-6 h-6 text-white" />
                    </a>
                  </div>
                </div>
              </div>

              {/* Project Details */}
              <div className="p-6">
                <h3 className="text-2xl font-bold text-gray-800 mb-2 dark:text-white">
                  {project.title}
                </h3>
                <p className="text-gray-600 mb-4 dark:text-gray-500">
                  {project.description}
                </p>

                {/* Tech Stack Badges */}
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.tech.map((tech, techIndex) => (
                    <span
                      key={techIndex}
                      className="px-3 py-1 text-sm rounded-full bg-indigo-100 text-indigo-800"
                    >
                      {tech}
                    </span>
                  ))}
                </div>

                {/* Project Links */}

                <div className="flex gap-4 mt-4">
                  {project.link && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={project.link}
                      className="flex items-center text-indigo-600 hover:text-indigo-800 transition-colors dark:text-indigo-500"
                    >
                      <EyeIcon className="w-5 h-5 mr-2" />
                      Live Demo
                    </a>
                  )}

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={project.github}
                    className="flex items-center text-gray-600 hover:text-gray-800 transition-colors dark:text-gray-400"
                  >
                    <CodeBracketIcon className="w-5 h-5 mr-2" />
                    View Code
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;
