import React from "react";

const SkillsSection = () => {
  const skillsData = [
    {
      name: "Next.js",
      description:
        "Building fast and scalable web applications with server-side rendering and static site generation.",
      icon: "🖥️", // Emoji or icon for visual appeal
      color: "bg-blue-100", // Background color for the icon
    },
    {
      name: "React",
      description:
        "Creating dynamic and responsive user interfaces with modern frontend development.",
      icon: "⚛️", // Emoji or icon for visual appeal
      color: "bg-pink-100", // Background color for the icon
    },
    {
      name: "Node.js",
      description:
        "Building scalable and efficient server-side applications with JavaScript.",
      icon: "💻", // Emoji or icon for visual appeal
      color: "bg-cyan-100", // Background color for the icon
    },
    {
      name: "Docker",
      description:
        "Containerizing applications for consistent deployment across environments.",
      icon: "🐳", // Emoji or icon for visual appeal
      color: "bg-indigo-100", // Background color for the icon
    },
    {
      name: "PostgreSQL",
      description:
        "Designing and managing robust relational databases for scalable applications.",
      icon: "🐘", // Emoji or icon for visual appeal
      color: "bg-purple-100", // Background color for the icon
    },
    {
      name: "Kubernetes",
      description:
        "Orchestrating containerized applications for seamless scaling and management.",
      icon: "☸️", // Emoji or icon for visual appeal
      color: "bg-green-100", // Background color for the icon
    },
    {
      name: "Terraform",
      description:
        "Implementing infrastructure as code for automated and scalable cloud environments.",
      icon: "🏗️", // Emoji or icon for visual appeal
      color: "bg-yellow-100", // Background color for the icon
    },
    // {
    //   name: "AWS",
    //   description:
    //     "Deploying and managing cloud infrastructure with Amazon Web Services.",
    //   icon: "☁️", // Emoji or icon for visual appeal
    //   color: "bg-orange-100", // Background color for the icon
    // },
    // {
    //   name: "Jenkins",
    //   description:
    //     "Automating CI/CD pipelines for efficient software delivery.",
    //   icon: "⚙️", // Emoji or icon for visual appeal
    //   color: "bg-red-100", // Background color for the icon
    // },
    {
      name: "Cybersecurity",
      description:
        "Implementing security best practices to protect applications and infrastructure.",
      icon: "🔒", // Emoji or icon for visual appeal
      color: "bg-gray-100", // Background color for the icon
    },
  ];

  return (
    <section className="py-16 bg-gradient-to-br from-gray-50 to-blue-50 dark:from-slate-900 dark:to-slate-900 dark:bg-slate-900">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12 dark:text-white">
          Skills & Tools
          <span className="block mt-2 text-2xl text-indigo-600 font-medium dark:text-indigo-500">
            What I Work With
          </span>
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {skillsData.map((skill, index) => (
            <div
              key={index}
              className="bg-white dark:bg-slate-800 rounded-xl shadow-lg overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-2xl"
            >
              <div className="p-8 text-center">
                <div
                  className={`w-16 h-16 ${skill.color} rounded-full flex items-center justify-center mx-auto mb-6 text-3xl`}
                >
                  {skill.icon}
                </div>
                <h3 className="text-2xl font-bold text-gray-800 mb-4 dark:text-white">
                  {skill.name}
                </h3>
                <p className="text-gray-600 leading-relaxed dark:text-gray-400">
                  {skill.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default SkillsSection;
